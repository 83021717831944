var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('Navbar',{attrs:{"navbarTitle":"Distributor Channels","primaryBtnIcon":"mdi-plus","primaryBtnText":"Create"},on:{"primaryEvent":function($event){_vm.createChannels = true;
        _vm.openDrawer();}},scopedSlots:_vm._u([{key:"secondaryBtnSlot",fn:function(){return [_c('v-switch',{staticClass:"mr-4",attrs:{"label":"Inactive","color":"primary","hide-details":"","dense":""},on:{"change":_vm.switchChannels},model:{value:(_vm.inactiveChannels),callback:function ($$v) {_vm.inactiveChannels=$$v},expression:"inactiveChannels"}})]},proxy:true}])}),_c('DataTable',{directives:[{name:"show",rawName:"v-show",value:(_vm.showChannelList || _vm.ChannelLoading),expression:"showChannelList || ChannelLoading"}],attrs:{"headers":_vm.tableHeaders,"items":_vm.channels,"pageCount":_vm.pages,"totalItems":_vm.totalChannels,"page":_vm.page,"loading":_vm.ChannelLoading},on:{"changePage":_vm.changePage},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var props = ref.props;
        var headers = ref.headers;
return [(headers.value == 'actions')?_c('span',[(_vm.inactiveChannels)?_c('div',[_c('v-btn',{staticClass:"suboverlineCapsReg primaryFABlue--text",staticStyle:{"border":"1px solid #d8d8d8","border-color":"#d8d8d8 !important"},attrs:{"color":"backgroundDark","height":"32","depressed":""},on:{"click":function($event){return _vm.activateChannel(props)}}},[_vm._v(" Activate ")])],1):_c('div',[_c('v-btn',{staticClass:"suboverlineCapsReg primaryFABlue--text",staticStyle:{"border":"1px solid #d8d8d8","border-color":"#d8d8d8 !important"},attrs:{"color":"backgroundDark","height":"32","depressed":""},on:{"click":function($event){return _vm.editChannel(props)}}},[_vm._v(" Edit ")]),_c('v-menu',{attrs:{"offset-y":"","close-on-scroll":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"color":"var(--blackMedium)"}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-horizontal ")])]}}],null,true)},[_c('v-list',{staticClass:"pa-0"},_vm._l((_vm.menuItems),function(menuItem,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.menuAction(props, index)}}},[_c('v-list-item-title',{class:menuItem.Color + "--text suboverlineCapsReg"},[_vm._v(_vm._s(menuItem.Text))])],1)}),1)],1)],1)]):_c('span',{class:props[headers.value] == '' ? "blackLow--text" : ""},[_vm._v(" "+_vm._s(props[headers.value] == "" ? headers.default : props[headers.value])+" ")])]}}])}),_c('Illustration',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showChannelList && !_vm.ChannelLoading),expression:"!showChannelList && !ChannelLoading"}],attrs:{"description":_vm.description,"subdescription":_vm.subdescription,"illustrationImg":"Binoculars.png"}}),_c('SideDrawer',{attrs:{"sidedrawerTitle":_vm.sidedrawerTitle,"sideDrawer":_vm.sideDrawer},on:{"update:sideDrawer":function($event){_vm.sideDrawer=$event},"update:side-drawer":function($event){_vm.sideDrawer=$event},"closeDrawer":_vm.closeSideDrawer},scopedSlots:_vm._u([{key:"bottomSlot",fn:function(){return [_c('v-btn',{staticClass:"overlineCapsReg mx-4",staticStyle:{"bottom":"16px"},style:(!_vm.valid
              ? "background-color: var(--highlightGray) !important; color: rgba(0, 0, 0, 0.6) !important;"
              : ""),attrs:{"color":"success","height":"32","block":"","depressed":"","absolute":"","disabled":!_vm.valid},domProps:{"textContent":_vm._s(
            _vm.valid
              ? _vm.createChannels
               ? "Create"
                : "Save Changes"
              : "Fill Mandatory Details"
          )},on:{"click":_vm.createOrViewChannel}})]},proxy:true}])},[_c('v-form',{ref:"form",staticClass:"mt-4 mb-4 ml-1-5",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{staticStyle:{"height":"50px"},attrs:{"placeholder":"Channel Name","rules":[
            function (v) { return !!v || 'Required'; },
            function (v) { return v.length < 100 || 'Name Cannot be more than 100 Characters'; } ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"red--text"},[_vm._v("* ")]),_vm._v("Channel Name ")]},proxy:true}]),model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}}),_c('v-text-field',{staticStyle:{"height":"50px"},attrs:{"placeholder":"Channel ERPId","rules":[function (v) { return !!v || 'Required'; }]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"red--text"},[_vm._v("* ")]),_vm._v("Channel ERPId ")]},proxy:true}]),model:{value:(_vm.editedItem.erpid),callback:function ($$v) {_vm.$set(_vm.editedItem, "erpid", $$v)},expression:"editedItem.erpid"}})],1)],1)],1),_c('Snackbar',{attrs:{"message":_vm.message,"snackbar":_vm.snackbar,"timeout":5000,"color":_vm.snackbarColor},on:{"update:snackbar":function($event){_vm.snackbar=$event},"closeSnackbar":_vm.closeSnackbar}}),_c('ActionablePopup',{attrs:{"popupDialog":_vm.confirmationPopup,"title":_vm.confirmationPopupTitle,"subtitle":_vm.confirmationPopupSubtitle,"text":_vm.confirmationPopupText,"data":_vm.selectedChannelId,"cancelBtnText":_vm.cancelBtnText,"confirmBtnText":_vm.confirmBtnText},on:{"close":function($event){_vm.confirmationPopup = false},"confirm":_vm.confirmAction}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }